<template>
  <div class="app-container">
    <!--    功能区域-->
    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd" >新增</el-button>
      <el-button type="danger" @click="deleteBatch" >批量删除</el-button>
    </div>
    <!--    表格区域-->
    <div class="table-container">
      <el-table ref="expenseTable"
                :data="tableData"
                style="width: 100%;"
                @selection-change="handleSelectionChange"
                v-loading="listLoading" border>
        <el-table-column type="selection" width="60" align="center"></el-table-column>
        <el-table-column label="编号" width="120" align="center">
          <template #default="scope">{{scope.row.id}}</template>
        </el-table-column>
        <el-table-column label="类型"  align="center" >
          <template #default="scope">{{scope.row.type}}</template>
        </el-table-column>
        <el-table-column label="周期"  align="center" >
          <template #default="scope">{{scope.row.cycle}}{{scope.row.choice}}</template>
        </el-table-column>
        <el-table-column label="费用(元)"  align="center" >
          <template #default="scope">{{scope.row.money}}</template>
        </el-table-column>
        <el-table-column label="操作" width="250" align="center">
          <template #default="scope">
            <el-button size="mini" @click="handleUpdate(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    分页-->
    <div style="margin: 10px 0">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes,prev, pager, next,jumper"
          :page-size="listQuery.pageSize"
          :page-sizes="[5,10,15,20,50,100,200,500,1000]"
          :current-page.sync="listQuery.pageNum"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog
        :title="isEditFlag == true?'编辑':'添加'"
        v-model="dialogVisible"
        width="40%">
      <el-form :model="expenseModel"
               ref="expenseFrom"
               label-width="150px"
               size="small">
        <el-form-item label="类型：">
          <el-select v-model="expenseModel.type"  placeholder="请选择" style="width: 60%">
            <el-option
                v-for="(item, index) in typeOptions"
                :key="index"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="周期：">
          <el-input-number v-model="expenseModel.cycle"  :step="1" style="width: 50%" clearable></el-input-number>
          <el-select v-model="expenseModel.choice"  placeholder="请选择" style="width: 80px">
            <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="费用(元)：">
          <el-input-number v-model="expenseModel.money" :min="0" style="width: 50%" clearable ></el-input-number>
        </el-form-item>
      </el-form>
      <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="handleDialogConfirm('expenseFrom')">提交</el-button>
          </span>
      </template>
    </el-dialog>

  </div>


</template>

<script>

import {batchDeleteExpense, deleteExpense, fetchList, updateExpense, createExpense} from "@/api/expense";

const defaultListQuery = {
  pageNum: 1,
  pageSize: 5,
  keyword: null,
};

const defaultExpense = {
  type: "",
  cycle: 0,
  choice: "月",
  money: 0,
  day: 0,
};

export default {
  name: 'AccountExpense',
  components: {},
  data() {
    return {
      listQuery: Object.assign({}, defaultListQuery),
      tableData: [],
      total: null,
      listLoading: false,
      ids: [],
      dialogVisible: false,
      isEditFlag: false,
      expenseModel: Object.assign({}, defaultExpense),

      options: [ // 下拉选项数组
        { label: '月', value: '月' },
        { label: '天', value: '天' },
        { label: '年', value: '年' },
      ],

      typeOptions: [ // 下拉选项数组
        { label: '首次开通', value: '首次开通' },
        { label: '续费', value: '续费' },
      ],

    }
  },
  created() {
    // 默认选择第一个选项
    this.getTableList()
  },

  methods: {
    //多选
    handleSelectionChange(val){
      this.ids = val.map(v => v.id);
    },
    // 改变当前每页的个数触发
    handleSizeChange(val) {
      this.listQuery.pageNum = 1;
      this.listQuery.pageSize = val;
      this.getTableList();
    },
    // 改变当前页码触发
    handleCurrentChange(val) {
      this.listQuery.pageNum = val;
      this.getTableList();
    },
    //删除
    handleDelete(row){
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteExpense(row.id).then(res => {
          if (res.code === '0') {
            /*this.$message({
              type: "success",
              message: "删除成功"
            })*/
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //批量删除
    deleteBatch() {
      if (!this.ids.length) {
        this.$message.warning("请选择数据！")
        return
      }
      this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        batchDeleteExpense(this.ids).then(res => {
          if (res.code === '0') {
            /*this.$message({
              type: "success",
              message: "批量删除成功"
            })*/
          } else {
            this.$message({
              type: "error",
              message: res.msg
            })
          }
          this.getTableList();
        });
      });
    },
    //列表
    getTableList() {
      this.listLoading = true;
      fetchList(this.listQuery).then(res => {
            if (res.code === '0') {
              this.listLoading = false;
              this.tableData = res.data.records;
              this.total = res.data.total;
            }
      })
    },
    //添加路由
    handleAdd() {
      this.dialogVisible = true;
      this.isEditFlag = false;
      this.expenseModel = Object.assign({},defaultExpense);
    },
    //修改路由
    handleUpdate(row) {
      this.dialogVisible = true;
      this.isEditFlag = true;
      this.expenseModel = Object.assign({},row);
      this.expenseModel.cycle = parseInt(row.cycle);
      this.expenseModel.money = parseFloat(row.money);
    },
    //确认操作
    handleDialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if(this.expenseModel.money <= 0){
            /*this.$message({
              type: "error",
              message:"费用需大于0"
            })*/
            return;
          }


          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (this.isEditFlag) {
              updateExpense(this.expenseModel).then(res => {
                if (res.code === '0') {
                  /*this.$message({
                    message: '修改成功',
                    type: 'success',
                  });*/
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });

            }else {
              createExpense(this.expenseModel).then(res => {
                if (res.code === '0') {
                  /*this.$message({
                    message: '提交成功',
                    type: 'success',
                  });*/
                }else {
                  this.$message({
                    type: "error",
                    message: res.msg
                  })
                }
                this.dialogVisible =false;
                this.getTableList();
              });
            }
          });
        }
      });
    },
  }
}
</script>
