import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/expense/list',
    method : 'post',
    data : data
  })
}

//删除
export function deleteExpense(data) {
  return request({
    url : '/expense/deleteExpense',
    method : 'post',
    data : data
  })
}
//批量删除
export function batchDeleteExpense(data) {
  return request({
    url : '/expense/batchDeleteExpense',
    method : 'post',
    data : data
  })
}

//新增
export function createExpense(data) {
  return request({
    url : '/expense/createExpense',
    method : 'post',
    data : data
  })
}
//修改
export function updateExpense(data) {
  return request({
    url :'/expense/updateExpense',
    method : 'post',
    data : data
  })
}

//全部
export function fetchAllExpense() {
  return request({
    url : '/expense/allExpense',
    method : 'get',
  })
}

//全部
export function fetchAllFirstExpense() {
  return request({
    url : '/expense/allFirstExpense',
    method : 'get',
  })
}

//全部
export function fetchAllNextExpense() {
  return request({
    url : '/expense/allNextExpense',
    method : 'get',
  })
}
